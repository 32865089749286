// src/context/SocketContext.jsx
import { BASE_SOCKET_URL } from "../api";
import React, { createContext, useEffect, useRef, useContext } from "react";
import { io } from 'socket.io-client';
import { AuthContext } from '../context/AuthContext';
import { useState } from "react";

export const SocketContext = createContext();

export function SocketProvider({ children }) {
  const socketRef = useRef(null);
  const { tokens, isAuthenticated } = useContext(AuthContext);
  const [connected, setConnected] = useState(false);
  // src/context/SocketContext.jsx

useEffect(() => {
  if (!isAuthenticated || !tokens) {
    if (socketRef.current) {
      console.log("User is not authenticated, disconnecting socket...");
      socketRef.current.disconnect();
      socketRef.current = null;
    }
    return;
  }

  if (socketRef.current) {
    console.log("Socket already connected.");
    return;
  }

  console.log("Initializing socket connection...");
  try {
    const newSocket = io(BASE_SOCKET_URL, {
      transports: ["websocket"],
      reconnectionAttempts: 10,
      reconnectionDelay: 5000,
      pingTimeout: 3000,
      query: {
        token: tokens.access_token
      }
    });

    socketRef.current = newSocket;

    // Event listeners
    newSocket.on('connect', () => {
      console.log('Socket connection established');
      setConnected(true); // Moved inside the 'connect' handler
    });

    newSocket.on('message', (message) => {
      console.log('Message received:', message);
    });

    newSocket.on("disconnect", () => {
      console.log("Socket disconnected.");
      setConnected(false);
    });

    newSocket.on("connect_error", (error) => {
      console.error("Socket connection failed:", error);
      if (error.message === "transport close") {
        console.log("Retrying socket connection...");
        newSocket.connect();
      }
    });

    newSocket.on("json", (json) => {
      console.log("Received JSON:", json);
    });
  } catch (error) {
    console.error("Failed to initialize socket:", error);
  }

  return () => {
    if (socketRef.current) {
      console.log("Cleaning up socket connection...");
      socketRef.current.disconnect();
      socketRef.current = null;
    }
  };
}, [isAuthenticated, tokens]);


  return (
    <SocketContext.Provider
      value={{
        socket: socketRef.current,
        setSocket: (socket) => { socketRef.current = socket; },
        connected,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
}
