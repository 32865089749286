import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Record from "../../assets/images/record.svg";
import UploadIcon from "../../assets/images/upload.png";
import sound from "../../assets/images/sound.png";
import play from "../../assets/images/playButtonUpload.svg";
import useScribeNow from "../../components/ScribeNow/scribeNow";
import ReusableButton from "../../components/Button/button";
import useSaveAudio from "../../hooks/saveAudio";
import loading from "../../assets/images/loading.svg";
import Pause from "../../assets/images/pauseButtonUpload.svg";
import { v4 as uuidv4 } from "uuid";
const Upload = () => {
  const [appointmentId] = useState(uuidv4());
  const { handleSaveAudio, isSaving } = useSaveAudio(appointmentId);
  const [templateData, setTemplateData] = useState({
    name: '',
    template: null,
  });
  const [currentTime, setCurrentTime] = useState(0); // Track current time
  const [audio, setAudio] = useState(null);
  const [isUploaded, setIsUploaded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [isTemplateUploaded, setIsTemplateUploaded] = useState(false);
  const localAudioRef = useRef(null);

  const location = useLocation();
  const { name } = location.state || {};

  const handleScribe = useScribeNow(name);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    // Check if a template is uploaded
    if (!isTemplateUploaded) {
      alert("Please upload a template first.");
      return; // Prevent further execution if no template is uploaded
    }

    if (!file) return;

    console.log("File selected:", file);
    const audioObject = new Audio(URL.createObjectURL(file));
    setAudio(audioObject);
    localAudioRef.current = audioObject;
    const audioBlob = new Blob([file], { type: "audio/wav" });
    console.log("Audio blob:", audioBlob);

    await handleSaveAudio([audioBlob]);
    console.log("New appointment ID:", appointmentId);

    setIsUploaded(true);
    getDurationFromFile(file);
  };

  const handleDrop = async (event) => {
    if (templateData.template == null) {
      alert("Please upload a template first.");
      return; // Prevent further execution if no template is uploaded
    }

    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      console.log("File dropped:", file);
      const audioObject = new Audio(URL.createObjectURL(file));
      setAudio(audioObject);
      localAudioRef.current = audioObject;
      // Convert file to audio blob
      const audioBlob = new Blob([file], { type: "audio/wav" });
      console.log("Audio blob:", audioBlob);


      setIsUploaded(true);
      getDurationFromFile(file);
    }
  };

  const handleDragOver = (event) => {
    if (templateData.template == null) {
      alert("Please upload a template first.");
      return; // Prevent further execution if no template is uploaded
    }

    event.preventDefault();
  };

  const getDurationFromFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      audioContext.decodeAudioData(e.target.result, (buffer) => {
        console.log("Duration:", buffer.duration);
        const timeDuration = convertSecondsToTime(buffer.duration);
        console.log("Time Duration:", timeDuration);
        setDuration(timeDuration);
      });
    };

    reader.readAsArrayBuffer(file);
  };

  const convertSecondsToTime = (seconds) => {
    const minutes = Math.floor(seconds / 60)
      .toString()
      .padStart(2, "0");
    const remainingSeconds = Math.floor(seconds % 60)
      .toString()
      .padStart(2, "0");
    return `${minutes}:${remainingSeconds}`;
  };

  const toggleMedia = () => {
    if (isPlaying) {
      localAudioRef.current.pause();
    } else {
      localAudioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleScribeButton = () => {
    if (!appointmentId) {
      console.error("No appointment ID found");
      return;
    }

    console.log("Scribing for", appointmentId);
    handleScribe(appointmentId, templateData.template);
  };

  const handleTemplateFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log("JSON template selected:", file);

      // Read JSON file
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = JSON.parse(event.target.result);
        console.log("JSON data:", data);
        setTemplateData({
          name: file.name,
          template: data,
        });
      };
      setIsTemplateUploaded(true);
      reader.readAsText(file);
    }
  };

  // Inside your Upload component

  const handleUploadClick = (event) => {
    if (!isTemplateUploaded) {
      alert("Please upload a template first.");
      event.preventDefault(); // Prevent the default action if no template is uploaded
    }
  };


  const handleSeek = (event) => {
    const seekTime = parseFloat(event.target.value); // Value will be in seconds
    localAudioRef.current.currentTime = seekTime;
    setCurrentTime(seekTime);
  };

  useEffect(() => {
    if (localAudioRef.current) {
      const audioElement = localAudioRef.current;
      const updateTime = () => setCurrentTime(audioElement.currentTime);
      audioElement.addEventListener("timeupdate", updateTime);

      return () => {
        audioElement.removeEventListener("timeupdate", updateTime);
      };
    }
  }, [audio]);


  return (
    <div className="px-6 py-4 bg-[#EBEDFD] min-h-screen">
      <div className="flex flex-row justify-between mb-6">
        <h1 className="text-xl font-extrabold mb-4 font-poppins">
          Upload Recording
        </h1>
        <div>
          <label htmlFor="file-template-upload" className="cursor-pointer">
            <input
              id="file-template-upload"
              type="file"
              accept=".json"
              className="hidden"
              onChange={handleTemplateFileChange}
            />
            <div className={`bg-[#83C3EC] text-[#FDFBFC] py-2 rounded-full font-bold font-poppins text-sm font-large px-4 tracking-wide`}>
              {templateData.name || 'Upload Template'}
            </div>
          </label>
        </div>
      </div>

      {isSaving && (
        <div className="flex justify-center items-center h-[calc(100vh-160px)]">
          <img src={loading} alt="loading" className="h-56 w-56 animate-spin" />
        </div>
      )}

      {!isSaving && (
        <div className="flex flex-col justify-center items-center">
          <div className="flex flex-row">
            <div className="relative flex justify-center items-center mb-4">
              {isUploaded ? (
                <div className="flex items-center mt-32 rounded-lg p-2 shadow-md w-[320px]">
                  <div className=" flex items-center">
                    <div className=" bg-black rounded-l-2xl px-6 py-5 flex items-center justify-center">
                      <button
                        className="flex items-center justify-center"
                        onClick={toggleMedia}
                      >
                        <img
                          src={!isPlaying ? play : Pause}
                          alt="Play Button"
                          className="w-6 h-full"
                        />
                      </button>
                    </div>
                    <div className=" bg-white rounded-r-2xl px-4 py-3 flex items-center justify-center">
                      <button className=" flex items-center px-1 py-2 justify-center">
                        <input
                          type="range"
                          min="0"
                          max={duration || 0}
                          value={currentTime}
                          onChange={handleSeek}
                          className="appearance-none w-32 h-2 mx-1 cursor-pointer"
                          style={{
                            background: `linear-gradient(to right, #BD7FEC 0%, #79CBEB ${((currentTime / duration) * 100) || 0}%)`, // Gradient
                            borderRadius: '5px',

                          }}
                          
                        />
                        <div className="text-[#121212] font-poppins font-bold mx-1  text-md flex items-center justify-center">
                          
                          <span>  {duration}</span>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                // Render the original images when isUploaded is false
                <label
                  htmlFor="file-upload"
                  className="cursor-pointer relative"
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  onClick={handleUploadClick} // Add the onClick handler here
                >
                  <input
                    id="file-upload"
                    type="file"
                    accept=".wav, .mp4, .mp3"
                    className="hidden"
                    onChange={handleFileChange}
                  />
                  <img src={Record} alt="Record" className="h-64 w-64" />
                  <img
                    src={UploadIcon}
                    alt="Upload"
                    className="h-20 w-20 absolute"
                    style={{
                      top: "47%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                  <span
                    className="absolute text-[#ACACAC] font-poppins text-lg font-large"
                    style={{
                      top: "65%",
                      left: "50%",
                      transform: "translateX(-50%)",
                    }}
                  >
                    Upload
                  </span>
                </label>
              )}
            </div>
          </div>

          {appointmentId ? (
            <div className="flex flex-col mt-6">
              <ReusableButton
                disabled={isSaving}
                onClick={handleScribeButton}
                label="Scribe Now"
                bgColor={isSaving ? "bg-[#D1D5DB]" : "bg-[#121212]"}
                textColor="text-[#FDFBFC]"
                additionalClasses="px-28"
              />
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center">
              <p className="font-poppins text-md font-large text-[#8B8888] text-center mx-44">
                Upload your consultation recording here. Whether it's audio or
                video, MedAI will process your file to enhance your scribing
                experience.
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );

};

export default Upload;
