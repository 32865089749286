import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

const Form = () => {
  const location = useLocation();
  const { name, appointmentId } = location.state || {};
  const [formData, setFormData] = useState({
    doctorName: "MALIK,MOHD AZFAR,MD",
    address1: "5000 CEDAR PLAZA PARKWAY",
    address2: "SAINT LOUIS, MO 63128",
    phone: "(314)590-3721",
    fax: "(314)214-7380",
    patientName: "BARTZ,LISA",
    dob: "7/22/2005",
    amMedMgmt: "7/28/2024",
    progressNote: "inPerson",
    chiefComplaint: "",
    appetite: false,
    sleep: false,
    currentMedications: "",
    medicationsEfficiency: false,
    medicationsCompliance: false,
    sideEffects: {
      none: false,
      involuntary: false,
      appetite: false,
      tremors: false,
      GI: false,
      sedation: false,
      akathisia: false,
      sexual: false,
    },
    other: '',
    comments: '',
    orientation: {
      alertandorientedx4: false,
      person: false,
      place: false,
      time: false,
    },
    rapport: {
      hostile: false,
      appropriate: false,
      distant: false,
      evasive: false,
      inattentive: false,
      pooreyecontact: false,
    },
    appearance: {
      appropriatelygroomed: false,
      poorlydressed: false,
      poorlygroomed: false,
      bodyodour: false,
    },
    mood: {
      euthymic: false,
      angry: false,
      anxious: false,
      depressed: false,
      elated: false,
      irritable: false,
    },
    speech: {
      appropriate: false,
      circumstantial: false,
      clanging: false,
      coherent: false,
      incoherent: false,
      looseassociations: false,
      loud: false,
      mute: false,
      perseveration: false,
      poverty: false,
      pressured: false,
      tangential: false,
      soft: false,
      wordsalad: false,
    },
    affect: {
      appropriate: false,
      blunted: false,
      depressed: false,
      expansive: false,
      flat: false,
      labile: false,
    },
    thoughtContentAndProcess: {
      appropriate: false,
      broadcasting: false,
      compulsions: false,
      delusional: false,
      goalDirected: false,
      grandiose: false,
      guilt: false,
      hallucination: false,
      homicidalideation: false,
      homicidalplan: false,
      hopelessness: false,
      loneliness: false,
      obsessions: false,
      persecution: false,
      phobias: false,
      reference: false,
      selfdeprecation: false,
      suicidalideation: false,
      suicidalplan: false,
      worthlessness: false,
      thoughtinsertion: false,
    },
    hallucinationType: {
      nonereported: false,
      auditory: false,
      command: false,
      visual: false,
    },
    insight: {
      excellent: false,
      good: false,
      fair: false,
      poor: false,
    },
    judgement: {
      excellent: false,
      good: false,
      fair: false,
      poor: false,
      grosslyimpaired: false,
    },
    cognitive: {
      nogrosscognitivedeficits: false,
      abstract: false,
      concrete: false,
      easilydistracted: false,
      concentrationproblems: false,
    },
    psychomotorActivity: {
      normal: false,
      restlessness: false,
      retardation: false,
    },
    // memory: {
    //   immediategood: false,
    //   immediatefair: false,
    //   immediateimpaired: false,
    //   recentgood: false,
    //   recentfair: false,
    //   recentimpaired: false,
    //   pastgood: false,
    //   pastfair: false,
    //   pastimpaired: false,
    // },
    thoughtProcessAndContent: {
      appropriate: false,
      broadcasting: false,
      compulsions: false,
      delusional: false,
      goalDirected: false,
      grandiose: false,
      guilt: false,
      hallucination: false,
      homicidalIdeation: false,
      homicidalPlan: false,
      hopelessness: false,
      loneliness: false,
      obsessions: false,
    },
    hallucinationTypes: {
      noneReported: false,
      auditory: false,
      command: false,
      visual: false,
    },
    insight: {
      excellent: false,
      fair: false,
      good: false,
      poor: false,
    },
    judgement: {
      excellent: false,
      fair: false,
      good: false,
      grosslyImpaired: false,
      poor: false,
    },
    cognitive: {
      noGrossCognitiveDisease: false,
      abstract: false,
      concentrationProblems: false,
      concrete: false,
      easilyDistracted: false,
    },
    psychomotorActivity: {
      normal: false,
      restlessness: false,
      retardation: false,
    },
    memory: {
      immediate: '',
      recent: '',
      past: '',
    },
    currentDiagnosis: "",
  });

  const handleProceedClick = async () => {
    try {
      const response = await axios.get(
        `https://medai-appointments-history.s3.amazonaws.com/${appointmentId}/scribe.json`
      //  `https://medai-appointments-history.s3.amazonaws.com/e45f6b56-4d68-4efc-8f8c-1d634f72d0e1/scribe.json`

      );
      const data = response.data;

      console.log("response", data);

      setFormData((prevState) => ({
        ...prevState,
        progressNote: data['Progress Note'] || '', 
        chiefComplaint: data['Medical Chief Complaint'] || '',
        appetite: data['Appetite'] || '',
        sleep: data['Sleep'] || '',
        currentMedications: data['Current Medications'] || '',
        medicationsEfficiency: data['Medication Efficacy'] || '',
        medicationsCompliance: data['Medication Compliance'] || '',
        sideEffects: data['Side Effects'] || '',
        other: data['Other'] || '',
        comments: data['Comments'] || '',
        orientation: data['Orientation'] || '',
        rapport: data['Rapport'] || '',
        appearance: data['Appearance'] || '',
        mood: data['Mood'] ||'',
        speech: data['Speech'] || '',
        affect: data['Affect'] || '',
        thoughtProcessAndContent: data['Thought Content and Process'] || '',
        hallucinationTypes: data['Hallucination Type(s)'] || '',
        insight: data['Insight'] || '',
        judgement: data['Judgement'] || '',
        cognitive: data['Cognitive'] || '',
        psychomotorActivity: data['Psychomotor Activity'] || '',
        memory: {
          immediate: data['Memory - Immediate'] || '',
          recent: data['Memory - Recent'] || '',
          past: data['Memory - Past'] || '',
        },
        currentDiagnosis: data['Current Diagnosis'] || '',
        
      }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleProceedClick();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  
  const handleMemoryChange = (memoryType, value) => {
    setFormData((prevData) => ({
      ...prevData,
      memory: {
        ...prevData.memory,
        [memoryType]: value,
      },
    }));
  };

  const sections = [
    {
      title: 'Orientation',
      options: [
          'Alert and oriented x4', 'Person', 'Place', 'Time'
      ],
      key: 'orientation',
    },
    {
      title: 'Rapport',
      options: [
        "Hostile",
        "Appropriate",
        "Distant",
        "Inattentive",
        "Evasive",
        "Poor Eye Contact",
      ],
      key: "rapport",
    },
    {
      title: "Appearance",
      options: [
        "Appropriately Groomed",
        "Poorly Dressed",
        "Poorly Groomed",
        "Body Odor",
      ],
      key: "appearance",
    },
    {
      title: "Mood",
      options: [
        "Euthymic",
        "Angry",
        "Anxious",
        "Depressed",
        "Elated",
        "Irritable",
      ],
      key: "mood",
    },
    {
      title: "Speech",
      options: [
        "Appropriate",
        "Circumstantial",
        "Clanging",
        "Coherent",
        "Incoherent",
        "Loose Associations",
        "Loud",
        "Mute",
        "Perseveration",
        "Poverty",
        "Pressured",
        "Tangential",
        "Soft",
        "Word Salad",
      ],
      key: "speech",
    },
    {
      title: "Affect",
      options: [
        "Appropriate",
        "Blunted",
        "Depressed",
        "Expansive",
        "Flat",
        "Labile",
      ],
      key: "affect",
    },
    {
      title: 'Thought Process and Content',
      options: [
        "Appropriate",
        "Broadcasting",
        "Compulsions",
        "Delusional",
        "Goal-Directed",
        "Grandiose",
        "Guilt",
        "Hallucination",
        "Homicidal Ideation",
        "Homicidal Plan",
        "Hopelessness",
        "Loneliness",
        "Obsessions",
        "Persecution",
        "Phobias",
        "Reference",
        "Self Deprecation",
        "Suicidal Ideation",
        "Suicidal Plan",
        "Worthlessness",
        "Thought Insertion",
      ],
      key: "thoughtProcessAndContent",
    },
    {
      title: "Hallucination Type(s)",
      options: ["None reported", "Auditory", "Command", "Visual"],
      key: "hallucinationTypes",
    },
    {
      title: "Insight",
      options: ["Excellent", "Fair", "Good", "Poor"],
      key: "insight",
    },
    {
      title: "Judgement",
      options: ["Excellent", "Fair", "Good", "Grossly Impaired", "Poor"],
      key: "judgement",
    },
    {
      title: "Cognitive",
      options: [
        "No Gross Cognitive Deficits",
        "Abstract",
        "Concentration Problems",
        "Concrete",
        "Easily Distracted",
      ],
      key: "cognitive",
    },
    {
      title: "Psychomotor Activity",
      options: ["Normal", "Restlessness", "Retardation"],
      key: "psychomotorActivity",
    },
  ];

  const sections1 = [
    {
      title: 'Side Effects',
      options: [
          'None', 'Involuntary Movements', 'Appetite Changes', 'Tremors', 'GI', 'Sedation', 'Akathisia', 'Sexual'
      ],
      key: 'sideEffects',
    }
  ]

  return (
    <div className="px-6 py-4 bg-[#EBEDFD] min-h-screen">
      <h1 className="text-xl font-extrabold mb-2 font-poppins">Scribed Form</h1>
      <div className="bg-white p-6 rounded-lg shadow-md max-w-4xl mx-auto">
        <div className="space-y-6">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col">
              <p className="font-bold font-poppins text-[#00000D] text-md">
                {formData.doctorName}
              </p>
              <p className="text-[#8B8888] font-bold font-poppins text-sm">
                {formData.address1}
              </p>
              <p className="text-[#8B8888] font-bold font-poppins text-sm">
                {formData.address2}
              </p>
            </div>
            <div className="flex flex-col">
              <div className="flex flex-row">
                <p className="font-bold font-poppins text-[#00000D] text-md">
                  Phone:{" "}
                </p>
                <p className="text-[#8B8888] font-bold font-poppins text-md">
                  {formData.phone}
                </p>
              </div>
              <div className="flex flex-row">
                <p className="font-bold font-poppins text-[#00000D] text-md">
                  Fax:
                </p>
                <p className="text-[#8B8888] font-bold font-poppins text-md">
                  {" "}
                  {formData.fax}
                </p>
              </div>
            </div>
          </div>

          <div>
            <div className="font-bold font-poppins text-[#00000D] text-sm">
              {formData.patientName ? formData.patientName : "John Smith"} -
              DOB:&nbsp;
              <p className="inline text-[#83C3EC]">
                {formData.dob ? formData.dob : "2/22/2005"}
              </p>
            </div>
            <div className="font-bold font-poppins text-[#00000D] text-sm">
              Am Med MGMT -&nbsp;
              <div className="inline text-[#83C3EC]">
                {formData.amMedMgmt
                  ? formData.amMedMgmt
                  : `${
                      new Date().getMonth() + 1
                    }/${new Date().getDate()}/${new Date()
                      .getFullYear()
                      .toString()
                      .slice(-2)}`}
              </div>
            </div>
          </div>

          <div>
            <p className="font-semibold mb-2 font-poppins text-[#00000D] text-sm">
              Progress Note
            </p>
            <div className="flex space-x-4">
              {["In Person", "Virtual", "Phone"].map((option) => (
                <label
                  key={option}
                  className="flex items-center text-[#8B8888] font-large font-poppins text-xs"
                >
                  <input
                    type="radio"
                    name="progressNote"
                    value={option.toLowerCase().replace(" ", "")}
                    checked={
                      formData.progressNote ===
                      option.toLowerCase().replace(" ", "")
                    }
                    onChange={handleInputChange}
                    className="mr-2"
                  />
                  {option}
                </label>
              ))}
            </div>
          </div>

            <div>
              <label className="block font-semibold mb-2 font-poppins text-[#00000D] text-sm">
                Chief Complaint
                <textarea
                  name="chiefComplaint"
                  value={formData.chiefComplaint}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded mt-1 font-poppins font-medium text-xs "
                  rows="3"
                />
              </label>
            </div>

          <label className="block font-semibold mb-2 font-poppins text-[#00000D] text-sm">
            Subjective Findings
          </label>
          <div className="flex flex-col gap-4">
            {[
              "appetite",
              "sleep",
              "currentMedications",
              "medicationsEfficiency",
              "medicationsCompliance",
            ].map((field) => {
              let options = [];
              if (field === "appetite") {
                options = ["Normal", "Good", "Fair", "Poor", "Overeating"];
              } else if (field === "sleep") {
                options = ["Normal", "Good", "Fair", "Poor"];
              } else if (
                field === "medicationsEfficiency" ||
                field === "medicationsCompliance"
              ) {
                options = ["Excellent", "Good", "Fair", "Poor", "Minimal"];
              }

              return (
                <div key={field}>
                  <label className="block mb-2 text-[#BD7FEC] font-poppins text-md font-bold">
                    {field.charAt(0).toUpperCase() +
                      field.slice(1).replace(/([A-Z])/g, " $1")}
                  </label>
                  {field === "currentMedications" ? (
                    <input
                      type="text"
                      name={field}
                      value={formData[field]}
                      onChange={handleInputChange}
                      className="w-1/2 p-2 border rounded mt-1 font-poppins font-medium text-xs"
                    />
                  ) : (
                    <div className="flex flex-row">
                      {options.map((option) => (
                        <label
                          key={option}
                          className="inline-flex items-center mr-4 text-[#8B8888] font-large font-poppins text-xs"
                        >
                          <input
                            type="radio"
                            name={field}
                            value={option}
                            checked={formData[field] === option}
                            onChange={handleInputChange}
                            className="form-radio text-purple-600"
                          />
                          <span className="ml-2">{option}</span>
                        </label>
                      ))}
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          <div className="flex flex-col">
            <p className="font-semibold font-poppins text-[#00000D] text-sm">Side Effects</p>
            <div className=''>
            {sections1.map((section) => (
              <div key={section.key} >
                <div className="grid grid-cols-3">
                  {section.options.map((option) => (
                    <label key={option} className="flex items-center text-[#8B8888] font-semibold font-poppins text-xs">
                      <input
                        type="checkbox"
                        checked={formData[section.key]===option}
                        onChange={handleInputChange}
                        className="mr-2"
                      />
                      {option}
                    </label>
                  ))}
                </div>
              </div>
            ))}
          </div>
          </div>

          <div>
            <label className="block text-[#BD7FEC] font-poppins text-md font-bold">
              Comments
              <textarea
                name="comments"
                value={formData.comments}
                onChange={handleInputChange}
                className="w-full p-2 border rounded mt-1 font-poppins font-medium text-xs text-[#00000D]"
                rows="3"
              />
            </label>
          </div>

      <div className='flex flex-col'>
        <h3 className="font-bold mb-2 font-poppins text-[#00000D] text-sm">Objective Findings</h3>
        <div className=''>
          {sections.map((section, index) => (
            <div key={`section-${index}`} className='mb-6'>
              <p className="mb-2 text-[#BD7FEC] font-poppins text-sm font-semibold">{section.title}</p>
              <div className="grid grid-cols-3">
                {section.options.map((option, optionIndex) => (
                  <label key={`option-${index}-${optionIndex}`} className="flex items-center text-[#8B8888] font-semibold font-poppins text-xs">
                    <input
                      type="checkbox"
                      checked={formData[section.key] === option}
                      onChange={(e) => handleInputChange(e)}
                      className="mr-2"
                    />
                    {option}
                  </label>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

    <div>
        <div className="grid grid-cols-4 gap-2">
          <label className="text-[#BD7FEC] font-poppins text-sm font-semibold">Memory</label>
          <label className="text-[#8B8888] font-semibold font-poppins text-xs text-center">Good</label>
          <label className="text-[#8B8888] font-semibold font-poppins text-xs text-center">Fair</label>
          <label className="text-[#8B8888] font-semibold font-poppins text-xs text-center">Impaired</label>
        </div>
        {['immediate', 'recent', 'past'].map((memoryType) => (
          <div key={memoryType} className="grid grid-cols-4 gap-2">
            <label className="text-[#8B8888] font-semibold font-poppins text-xs">
              {memoryType.charAt(0).toUpperCase() + memoryType.slice(1)}
            </label>
            {['Good', 'Fair', 'Impaired'].map((option) => (
              <label key={option} className="flex justify-center">
                <input
                  type="checkbox"
                  checked={formData.memory[memoryType] === option}
                  onChange={() => handleMemoryChange(memoryType, option)}
                  className="mr-2"
                />
              </label>
            ))}
          </div>
        ))}
      </div>


    <div>
      <label className="block text-[#BD7FEC] font-poppins text-md font-bold">
        Comments
        <textarea
          name="comments"
          value={formData.comments}
          type='text'
          onChange={handleInputChange}
          className="w-full p-2 border rounded mt-1 font-poppins font-medium text-xs text-[#00000D]"
          rows="3"
        />
      </label>
    </div>

    <div>
    <h3 className="font-bold mb-2 font-poppins text-[#00000D] text-sm">Assessment</h3>
      <label className="block text-[#BD7FEC] font-poppins text-md font-bold">
        Current Diagnosis
        <textarea
          name="comments"
          type= 'text'
          value={formData.currentDiagnosis}
          onChange={handleInputChange}
          className="w-full p-2 border rounded mt-1 font-poppins font-medium text-xs text-[#00000D]"
          rows="3"
        />
      </label>
    </div>

    </div>
    </div>
    </div>
  );
};

export default Form;
