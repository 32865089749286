import React from "react";
import Skeleton from '../../components/Skeleton/skeleton';
import Upload from './Upload';

const UploadPage = () => {
	return (
		<Skeleton>
			<Upload />
		</Skeleton>
	);
};

export default UploadPage;