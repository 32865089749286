import React from 'react';
import Skeleton from '../../components/LoginSignUpSkeleton/loginSignupSkeleton.jsx';
import SignUpForm from './SignUp.jsx';

const SignUpPage = () => {
    return (
      <Skeleton >
        <SignUpForm/>
      </Skeleton>
    );
  };
  
  export default SignUpPage;
  