import React, { useEffect, useState } from "react";
import { useAppointments } from "../../context/AppointmentsContext.js"; // Import context hook
import { useNavigate } from "react-router-dom";
import Mic from "../../assets/images/microphone.svg";
import Video from "../../assets/images/video.svg";
import ReusableButton from "../../components/Button/button";
import { BASE_API_URL } from "../../api";
import Skeleton from "../../components/Skeleton/skeleton";

const AppointmentItem = ({ name, date, start_time,end_time, id, tag }) => {
  const navigate = useNavigate();

  const handleMicClick = (e) => {
    e.stopPropagation();
    console.log("Recording for", id);
    navigate("/record", { state: { name, appointmentId: id } });
  };

  const handleVideoClick = (e) => {
    e.stopPropagation();
    console.log("Recording for", id);
    navigate("/videorecording", { state: { name, appointmentId: id } });
  };

  return (
    <div
      className="bg-white rounded-3xl p-3 shadow-custom cursor-pointer text-left w-full"
    >
      <div className="flex justify-between items-center">
        <div className="px-2">
          <h2 className="text-lg font-semibold font-poppins text-[#BD7FEC] mb-2">
            {name}
          </h2>
          <p className="text-sm font-lg text-[#ACACAC] font-poppins mb-1">
            Appointment Details
          </p>
          <p className="text-xs font-lg text-[#000000] font-poppins mb-1">
            {date}
          </p>
          <p className="text-xs font-lg text-[#000000] font-poppins">{start_time.slice(0, -3)} - {end_time.slice(0, -3)}</p>
        </div>
        <div className="flex space-x-2 px-2">
          <ReusableButton
            onClick={handleMicClick}
            icon={<img src={Mic} alt="Audio call" className="w-5 h-5" />}
            bgColor="bg-gray-800"
            additionalClasses="p-2 rounded-full"
          />
          <ReusableButton
            onClick={handleVideoClick}
            icon={<img src={Video} alt="Video call" className="w-5 h-5" />}
            bgColor="bg-gray-800"
            additionalClasses="p-2 rounded-full"
          />
        </div>
      </div>
      {tag && (
        <div className="mt-2">
          <span className="bg-yellow-200 text-yellow-800 text-xs font-medium px-2 py-1 rounded">
            {tag}
          </span>
        </div>
      )}
    </div>
  );
};
const Appointment = ({ tokens }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { appointments, setAppointments } = useAppointments(); 
  const [localAppointments, setLocalAppointments] = useState([]);

  useEffect(() => {
    const fetchAppointments = async () => {
      setIsLoading(true); // Ensure loading state is set when the request starts
      try {
          console.log("Fetching appointments...");
          console.log("Tokens: ", tokens?.access_token);
          const response = await fetch(`${BASE_API_URL}/api/appointments`, {
                headers: {
                  "Authorization": `Bearer ${tokens?.access_token}`,
              },
          });
  
          const data = await response.json(); // Assume response will always return JSON
          console.log("Received appointments: ", data);
  
          if (response.ok) { // Checks if status is within 200-299
              setAppointments(data);
              setLocalAppointments(data);
          } else {
              console.error("Error fetching appointments: ", data);
              // // Set an error state or show an error message based on 'data' or 'response.status'
              // setError(`Failed to fetch appointments: ${data.message || response.statusText}`);
          }
      } catch (error) {
          console.error("Error fetching appointment history: ", error);
          // Handle network error, parsing error, etc.
      } finally {
          setIsLoading(false); // Ensure loading state is cleared
      }
     };
  
      console.log("Tokens: ", tokens);
      if (tokens) { // Ensure tokens are available before fetching
          fetchAppointments();
      }
  }, [setAppointments, tokens]); // Add tokens to dependency array

  if (isLoading) {
      return (
          <Skeleton>
              <div className="px-6 py-4 bg-[#EBEDFD] min-h-screen">
                  <h1 className="text-xl font-extrabold mb-6 font-poppins">Appointments</h1>
                  <p>Loading...</p>
              </div>
          </Skeleton>
      );
  }

  return (
      <Skeleton appointments={localAppointments}>
          <div className="px-6 py-4 bg-[#EBEDFD] min-h-screen">
              <h1 className="text-xl font-extrabold mb-6 font-poppins">Appointments</h1>
              <div className="flex flex-col space-y-4">
                  {appointments.map((appointment) => (
                      <AppointmentItem key={appointment.id} {...appointment} />
                  ))}
              </div>
          </div>
      </Skeleton>
  );
};

export default Appointment;
