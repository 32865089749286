import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Record from "../../assets/images/record.svg";
import Mic from "../../assets/images/mic.svg";
import Mic2 from "../../assets/images/mic.gif";
import Pause from "../../assets/images/pause.svg";
import Resume from "../../assets/images/resume.svg";
import Question from "../../assets/images/question.svg";
import Rec from "../../assets/images/recblue.svg";
import useScribeNow from "../../components/ScribeNow/scribeNow";
import useSaveForLater from "../../components/SaveLater/saveLater";
import useSuggestions from "../../components/Suggestions/suggestions";
import ReusableButton from "../../components/Button/button";
import useSaveAudio from "../../hooks/saveAudio";
import { SocketContext } from "../../context/SocketContext";
import exampleTemplate from "../../assets/doctor_template.json";

const RecordSession = () => {
  const { socket, connected } = useContext(SocketContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { name, appointmentId } = location.state || {};
  const ismsg = useRef(true);
  const stop = false;
  // Add this at the top of your component
const isFinalRecordingSaved = useRef(false);


  const [isPaused, setIsPaused] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [timer, setTimer] = useState(0);
  const [recordingEnded, setRecordingEnded] = useState(false);
  const [templateUploaded, setTemplateUploaded] = useState(false);
  const [topQuestions, setTopQuestions] = useState([]);
  const topQuestionsRef = useRef([]);

  const [templateData, setTemplateData] = useState({
    name: "",
    template: null,
  });

  const handleScribeNow = useScribeNow(name);
  const handleSaveForLater = useSaveForLater();
  const { handleSaveAudio, isSaving } = useSaveAudio(appointmentId);
  const { suggestions, setSuggestions, generateSuggestions } = useSuggestions();

  const removeSuggestion = (index) => {
    setTopQuestions((prevQuestions) => prevQuestions.filter((_, i) => i !== index));
  };

  // Refs for mutable values
  const mediaRecorderRef = useRef(null);
  const streamRef = useRef(null);
  const completeRecordingBuffer = useRef([]);
  const recordingEndedRef = useRef(recordingEnded);
  const isPausedRef = useRef(isPaused);
  const timerRef = useRef(timer);
  const timeoutIdRef = useRef(null);
  const messageIdRef = useRef(1); // For unique message IDs

  useEffect(() => {
    topQuestionsRef.current = topQuestions;
  }, [topQuestions]);

  useEffect(() => {
    recordingEndedRef.current = recordingEnded;
  }, [recordingEnded]);

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  useEffect(() => {
    timerRef.current = timer;
  }, [timer]);

  useEffect(() => {
    if (!appointmentId) {
      navigate("/");
      return;
    }

    

    let interval;
    if (isRecording && !recordingEnded) {
      interval = setInterval(() => {
        if (!isPausedRef.current) {
          setTimer((prevTimer) => prevTimer + 1);
        }
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isRecording, recordingEnded, appointmentId, navigate]);

  const toggleRecording = () => {
    if (!templateUploaded) {
      alert("Please upload a template before starting the recording.");
      return;
    }

    if (!isRecording) {
      startRecording();
    } else {
      stopRecording();
    }
  };

  const togglePauseResume = () => {
    if (!mediaRecorderRef.current) return;

    if (mediaRecorderRef.current.state === "recording") {
      mediaRecorderRef.current.pause();
      setIsPaused(true);
      console.log("Recording paused");
    } else if (mediaRecorderRef.current.state === "paused") {
      // Check if the recorder is inactive (might have been stopped)
      if (mediaRecorderRef.current.state === "inactive") {
        // Start a new recorder if the previous one has stopped
        startNewRecorder();
        console.log("Recording resumed from inactive state");
      } else {
        mediaRecorderRef.current.resume();
        console.log("Recording resumed");
      }
      setIsPaused(false);
    } else if (
      mediaRecorderRef.current.state === "inactive" &&
      !recordingEndedRef.current
    ) {
      // Start a new recorder if the previous one has stopped
      startNewRecorder();
      setIsPaused(false);
      setIsRecording(true);
      console.log("Recording resumed from inactive state");
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const startRecording = () => {
    if (recordingEndedRef.current) return; // Do not start if recording has ended

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        streamRef.current = stream;
        startNewRecorder();
        setIsRecording(true);
        setIsPaused(false);
      })
      .catch((err) => {
        console.error("Error accessing audio devices:", err);
      });
  };

  const startNewRecorder = () => {
    if (recordingEndedRef.current) return;
    if (!streamRef.current) return; // Stream is not available

    const mediaRecorder = new MediaRecorder(streamRef.current, {
      mimeType: "audio/webm; codecs=opus",
    });
    mediaRecorderRef.current = mediaRecorder;
    let chunks = [];

    mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        chunks.push(event.data);
        completeRecordingBuffer.current.push(event.data);
        console.log("Chunk received:", event.data.size);
      }
    };

    mediaRecorder.onstop = () => {
      const audioBlob = new Blob(chunks, { type: "audio/webm" });
      chunks = []; // Reset chunks for the next interval
      const reader = new FileReader();
      reader.onloadend = () => {
        const arrayBuffer = reader.result;
        const audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        audioContext.decodeAudioData(
          arrayBuffer,
          (audioBuffer) => {
            const duration = audioBuffer.duration;
            console.log("Audio chunk duration:", duration);
            if (duration >= 5) {
              // Save the audio chunk
              if (ismsg.current ) {
                handleSaveAudio([audioBlob])
                  .then(() => {
                    console.log("Audio chunk saved successfully");
                    console.log(
                      "topQuestions when sending audio_chunk:",
                      topQuestionsRef.current
                    );
                    // Send message via socket
                    const message = {
                      message_type: 1,
                      message_id: messageIdRef.current++,
                      route: "audio_chunk",
                      message_object: {
                        appointment_id: appointmentId,
                        key_questions: topQuestionsRef.current,
                      },
                    };
    
                    if (connected) {
                      try {
                        socket.emit("message", [
                          message.message_type,
                          message.message_id,
                          message.route,
                          message.message_object,
                        ]);
                        console.log(
                          "Audio chunk message sent via socket:",
                          message
                        );
                      } catch (error) {
                        console.log(error);
                      }
                    }
                  })
                  .catch((error) =>
                    console.error("Error saving audio chunk:", error)
                  );
              }
            } else {
              console.log("Audio chunk less than 5 seconds, not saving");
            }
    
            // Start a new recorder if needed
            if (!recordingEndedRef.current && !isPausedRef.current) {
              try {
                startNewRecorder(); // Start a new recording session
              } catch (error) {
                console.error("Error starting new recorder:", error);
              }
            } else {
              console.log(
                "Recorder stopped and will not restart because it's paused or recording has ended."
              );
              // If recording has ended and final recording is not yet saved
              if (recordingEndedRef.current && !isFinalRecordingSaved.current) {
                isFinalRecordingSaved.current = true; // Set the flag
                const completeAudioBlob = new Blob(
                  completeRecordingBuffer.current,
                  {
                    type: "audio/webm",
                  }
                );
                // Create an object URL for the audio Blob
                const audioUrl = URL.createObjectURL(completeAudioBlob);
                // Create an Audio element and set its source to the object URL
                const audio = new Audio(audioUrl);
                // When the metadata is loaded, log the duration
                audio.addEventListener("loadedmetadata", () => {
                  console.log("Audio duration:", audio.duration, "seconds");
                  // Release the object URL to free up memory
                  URL.revokeObjectURL(audioUrl);
                });
                handleSaveAudio([completeAudioBlob])
                  .then(() =>
                    console.log("Complete recording saved successfully")
                  )
                  .catch((error) =>
                    console.error("Error saving complete recording:", error)
                  );
              }
            }
          },
          (error) => {
            console.error("Error decoding audio data", error);
          }
        );
      };
      reader.readAsArrayBuffer(audioBlob);
    };
    

    mediaRecorder.start();

    // Calculate the delay until the next 15s interval
    const timerValue = timerRef.current || 0;
    const remainder = timerValue % 120;
    let delayInSeconds = (120 - remainder) % 120;
    if (delayInSeconds === 0) delayInSeconds = 120;

    const delayInMilliseconds = delayInSeconds * 1000;

    console.log(`MediaRecorder will stop in ${delayInSeconds} seconds`);

    // Clear any existing timeout
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }

    // Stop the recorder after delayInMilliseconds
    timeoutIdRef.current = setTimeout(() => {
      if (mediaRecorder.state !== "inactive") {
        mediaRecorder.stop();
      }
    }, delayInMilliseconds);
  };

  const stopRecording = () => {
    console.log("Recording stopped");
    setIsRecording(false);
    setRecordingEnded(true);
    ismsg.current = false;
  
    // Stop the current recorder
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop();
    }
  
    // Clear any pending timeout
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }
  
    // Stop the stream
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null; // Reset the streamRef
    }
  };
  
  

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log("JSON template selected:", file);

      // Read JSON file
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = JSON.parse(event.target.result);
        console.log("JSON data:", data);
        setTemplateData({
          name: file.name,
          template: data,
        });
        setTemplateUploaded(true); // Set template as uploaded

        // Send message via socket with the template
        const message = {
          message_type: 1,
          message_id: messageIdRef.current++,
          route: "key_questions",
          message_object: {
            DoctorTemplate: data || exampleTemplate,
            num_key_questions: 5,
          },
        };
        console.log("Emitting record event with message", message);

        // Send the message via socket
        if (connected) {
          try {
            socket.emit("message", [
              message.message_type,
              message.message_id,
              message.route,
              message.message_object,
            ]);
            console.log("Message sent via socket:", message);
          } catch (error) {
            console.log(error);
          }
        }
      };

      reader.readAsText(file);
    }
  };

  useEffect(() => {
    if (isFinalRecordingSaved.current === false) 
      {
        const handleSocketMessage = (response) => {
          console.log("Socket message received:", response);
          if (Array.isArray(response) && response.length > 0) {
            const [messageType, messageId, route, messageObject] = response;
            console.log("Parsed message:", {
              messageType,
              messageId,
              route,
              messageObject,
            });
      
            if (
              route === "key_questions" ||
              route === "question_feedback" ||
              route === "audio_chunk"
            ) {
              let keyQuestions = [];
      
              if (Array.isArray(messageObject)) {
                keyQuestions = messageObject;
              } else if (
                messageObject &&
                Array.isArray(messageObject.key_questions)
              ) {
                keyQuestions = messageObject.key_questions;
              }
      
              if (keyQuestions.length > 0) {
                setTopQuestions(keyQuestions);
                console.log("Updated topQuestions:", keyQuestions);
              } else {
                console.log("No key questions found in messageObject");
              }
            } else {
              console.log(
                "Received message with route:",
                route,
                "messageObject:",
                messageObject
              );
            }
          } else {
            console.log("Unexpected response format:", response);
          }
        };
      
        if (socket && connected) {
          socket.on("message", handleSocketMessage);
      
          return () => {
            socket.off("message", handleSocketMessage);
          };
        }
      }
  }, [socket, connected]);
  

  useEffect(() => {
    return () => {
      // Cleanup on unmount
      if (
        mediaRecorderRef.current &&
        mediaRecorderRef.current.state !== "inactive"
      ) {
        mediaRecorderRef.current.stop();
      }
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  return (
    <div className="px-6 py-4 bg-[#EBEDFD] min-h-screen">
      <h1 className="text-xl font-extrabold mb-2 font-poppins">Record Session</h1>
      <div className="flex flex-row justify-between mb-6">
        <div className="flex flex-row">
          <p className="font-poppins text-sm font-semibold">
            You're Currently in the session with &nbsp;
          </p>
          <p className="font-poppins text-sm font-semibold text-[#BD7FEC]">
            {name}
          </p>
        </div>
        {!isRecording && !recordingEnded && (
          <div>
            <label htmlFor="file-upload" className="cursor-pointer">
              <input
                id="file-upload"
                type="file"
                accept=".json"
                className="hidden"
                onChange={handleFileChange}
              />
              <div
                className={`bg-[#83C3EC] text-[#FDFBFC] py-2 rounded-full font-bold font-poppins text-sm font-large px-4 tracking-wide`}
              >
                {templateData.name || "Upload Template"}
              </div>
            </label>
          </div>
        )}
      </div>

      <div className="flex flex-col justify-center items-center">
        <div className="flex flex-row">
          <div className="relative flex justify-center items-center mb-4">
            <button onClick={toggleRecording} disabled={recordingEnded}>
              <img src={Record} alt="Record" className="h-64 w-64" />
              <img
                src={isRecording && !isPaused ? Mic2 : Mic}
                alt="Mic"
                className="h-28 w-28 absolute"
                style={{
                  top: "45%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              />
              <span
                className="absolute text-[#ACACAC] font-poppins text-lg font-large"
                style={{
                  top: "65%",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                {recordingEnded
                  ? formatTime(timer)
                  : isRecording
                  ? formatTime(timer)
                  : "Start"}
              </span>
            </button>
          </div>
          {isRecording && !recordingEnded && (
            <button onClick={togglePauseResume}>
              <img
                alt="Pause/Resume"
                src={isPaused ? Resume : Pause}
                className={`relative w-16 h-16 text-[#ACACAC] rounded-full bg-[#FDFBFC] shadow-custom p-4 ${
                  isPaused ? "pr-1" : ""
                }`}
                style={{
                  top: "10%",
                  left: "20%",
                  transform: "translate(10%, -70%)",
                }}
              />
            </button>
          )}
        </div>
        {recordingEnded && (
          <div className="flex flex-col space-y-4 mt-6">
            <ReusableButton
              onClick={() => handleScribeNow(appointmentId, templateData.template)}
              label={isSaving ? "Saving..." : "Scribe Now"}
              bgColor="bg-[#121212]"
              textColor="text-[#FDFBFC]"
              additionalClasses="px-20"
              disabled={isSaving}
            />
            <ReusableButton
              onClick={handleSaveForLater}
              label={isSaving ? "Saving..." : "Save for Later"}
              bgColor="bg-[#83C3EC]"
              textColor="text-[#FDFBFC]"
              additionalClasses="px-20"
              disabled={isSaving}
            />
          </div>
        )}
        {isRecording && !recordingEnded && (
          <div className="space-y-4 mt-6 flex flex-col justify-center items-center">
            {topQuestions.slice(0, 3).map((question, index) => (
              <div
                key={index}
                className="flex flex-col sm:flex-row bg-[#FDFBFC] text-[#000000] rounded-lg shadow-custom w-full max-w-3xl p-4"
              >
                <div className="relative flex-shrink-0 mb-4 sm:mb-0 sm:mr-4">
                  <img src={Rec} alt="Rec" className="w-12 h-12" />
                  <img
                    src={Question}
                    alt="Question"
                    className="absolute top-3 left-3 w-8 h-8"
                  />
                </div>
                <div className="flex-1 flex items-center">
                  <p className="font-poppins text-sm font-semibold tracking-wide">
                    {question}
                  </p>
                </div>
                <p
                  className="text-[#ACACAC] cursor-pointer ml-4 sm:ml-2 text-lg"
                  onClick={() => removeSuggestion(index)}
                >
                  x
                </p>
              </div>
            ))}
          </div>
        )}
        {!isRecording && !recordingEnded && (
          <div className="flex flex-col justify-center items-center">
            <p className="font-poppins text-md font-semibold text-[#BD7FEC] text-center">
              Get Ready to Capture Every Detail—Including the Emotions!
            </p>
            <p className="font-poppins text-md font-large text-[#8B8888] text-center mx-44">
              You're about to start live recording. Our cutting-edge scribing
              technology ensures that every word and emotion is captured, so
              nothing gets missed. Ready to create seamless, empathetic medical
              notes in real-time? Let's begin!
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default RecordSession;