import React from "react";
import Skeleton from '../../components/Skeleton/skeleton';
import Form from "./FormScreen";

const FormPage = () => {
	return (
		<Skeleton>
			<Form />
		</Skeleton>
	);
};

export default FormPage;