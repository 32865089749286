import React from "react";
import Skeleton from '../../components/Skeleton/skeleton';
import ScribingDone from "./ScribingDone";

const ScribingDonePage = () => {
    return (
        <Skeleton>
            <ScribingDone/>
        </Skeleton>
    );
};

export default ScribingDonePage;