import React from "react";
import Skeleton from '../../components/Skeleton/skeleton';
import VideoRecording from "./VideoRecording";

const VideoRecordingPage = () => {
    return (
        <Skeleton>
            <VideoRecording/>
        </Skeleton>
    );
};

export default VideoRecordingPage;