import React from "react";
import Skeleton from '../../components/Skeleton/skeleton';
import Scribing from "./Scribing";

const ScribingPage = () => {
    return (
        <Skeleton>
            <Scribing/>
        </Skeleton>
    );
};

export default ScribingPage;