import { useState, useEffect, useCallback, useRef } from "react";
import useSaveAudio from "./saveAudio";

export default function useRecording(appointmentId = null) {
  const [isPaused, setIsPaused] = useState(false);
  const [audioChunks, setAudioChunks] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const { isSaving, handleSaveAudio } = useSaveAudio(null);
  const [recordingEnded, setRecordingEnded] = useState(false);

  useEffect(() => {
    if (mediaRecorderRef.current) {
      const handleDataAvailable = async (event) => {
        console.log("Data available:", event.data);
        setAudioChunks((prevChunks) => [...prevChunks, event.data]);

        await handleSaveAudio([...audioChunks, event.data], appointmentId);
      };

      const handleStop = async () => {
        console.log("Stopped media recorder:", mediaRecorderRef.current);
        setIsRecording(false);
        setRecordingEnded(true);
        if (mediaRecorderRef.current.stream) {
          mediaRecorderRef.current.stream.getTracks().forEach((track) => track.stop());
        }
      };

      mediaRecorderRef.current.addEventListener("dataavailable", handleDataAvailable);
      mediaRecorderRef.current.addEventListener("stop", handleStop);
      
      return () => {
        mediaRecorderRef.current.removeEventListener("dataavailable", handleDataAvailable);
        mediaRecorderRef.current.removeEventListener("stop", handleStop);
      };
    }
  }, [audioChunks, handleSaveAudio, appointmentId]);

  const startRecording = useCallback(() => {
    console.log("Recording started");
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const newMediaRecorder = new MediaRecorder(stream);
        newMediaRecorder.start();
        console.log("Started media recorder");
        setIsRecording(true);
        setIsPaused(false);
        mediaRecorderRef.current = newMediaRecorder;
      })
      .catch((err) => console.error("Error accessing audio devices:", err));
  }, []);

  const togglePauseResume = useCallback(() => {
    if (isRecording && !recordingEnded) {
      if (isPaused) {
        console.log("Recording resumed");
        mediaRecorderRef.current.resume();
      } else {
        console.log("Recording paused");
        mediaRecorderRef.current.pause();
      }
      setIsPaused(!isPaused);
    }
  }, [isRecording, recordingEnded, isPaused]);

  const stopRecording = useCallback(() => {
    if (mediaRecorderRef.current) {
      console.log("Recording stopped");
      mediaRecorderRef.current.stop();
    } else {
      console.error("MediaRecorder is null, cannot stop recording");
    }
  }, []);

  return { isRecording, isPaused, isSaving, startRecording, togglePauseResume, stopRecording };
}
