// src/screens/Login/LoginForm.jsx
import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext'; // Adjust the import path as necessary
import { BASE_API_URL } from "../../api";
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import eye icons from react-icons

const LoginForm = () => {
    const { setTokens, logout } = useContext(AuthContext); // Use setTokens and logout from AuthContext
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [showPassword, setShowPassword] = useState(false); // State to handle password visibility
    const navigate = useNavigate();

    // Use useEffect to logout when component mounts
    useEffect(() => {
        logout();
    }, [logout]); // Include 'logout' in the dependency array

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        try {
            const response = await axios.post(`${BASE_API_URL}/api/login`, {
                email: formData.email,
                password: formData.password,
            });

            if (response.status === 200) {
                const { access_token, refresh_token } = response.data;
                setSuccess('Login successful!');
                
                // Save tokens in context
                setTokens({ access_token, refresh_token });

                // Redirect to appointments
                navigate('/appointments');
            }
        } catch (error) {
            setError('Invalid login credentials. Please try again.');
        }
    };

    // Toggle password visibility
    const toggleShowPassword = () => {
        setShowPassword((prevState) => !prevState);
    };

    return (
        <div className="bg-[#EBEDFD] flex justify-center items-center min-h-screen">
            <div className="w-9/12">
                <h2 className="text-2xl font-extrabold font-poppins text-black mb-4">Log in</h2>

                {/* Display error or success messages */}
                {error && <p className="text-red-500">{error}</p>}
                {success && <p className="text-green-500">{success}</p>}

                <form onSubmit={handleSubmit}>
                    {/* Email Section */}
                    <div className="mb-2">
                        <label className="block text-base font-bold text-black">Email</label>
                        <input
                            type="text"
                            name="email"
                            placeholder="Enter your Email/Phone Number"
                            value={formData.email}
                            onChange={handleInputChange}
                            className="w-full px-3 py-1 border-b-2 border-black text-gray-600 focus:outline-none focus:border-black bg-transparent text-sm"
                        />
                    </div>

                    {/* Password Section */}
                    <div className="mb-2 relative">
                        <label className="block text-base font-bold text-black">Password</label>
                        <span
                            onClick={toggleShowPassword}
                            className="absolute right-0 top-[30px] cursor-pointer text-gray-600"
                        >
                            {showPassword ? <FaEyeSlash /> : <FaEye />} {/* Switch between eye and eye-slash */}
                        </span>
                        <input
                            type={showPassword ? 'text' : 'password'} // Conditionally change the input type
                            name="password"
                            placeholder="Enter your Password"
                            value={formData.password}
                            onChange={handleInputChange}
                            className="w-full px-3 py-1 border-b-2 border-black text-gray-600 focus:outline-none focus:border-black bg-transparent text-sm"
                        />
                    </div>

                    {/* Remember Me and Forgot Password */}
                    <div className="flex justify-between items-center mb-4">
                        <label className="flex items-center text-black text-sm">
                            <input
                                type="checkbox"
                                className="w-4 h-4 border-2 border-black mr-2"
                            />
                            Remember Me
                        </label>
                        <a
                            href="/forgot-password" // Provide a valid href
                            className="text-[#A18ADF] font-medium text-sm"
                        >
                            Forgot Password?
                        </a>
                    </div>

                    {/* Login Button */}
                    <button
                        type="submit"
                        className="w-full bg-black text-white py-2 rounded-full text-sm font-bold hover:bg-gray-800 transition duration-300"
                    >
                        Login
                    </button>

                    {/* Sign Up Link */}
                    <div className="mt-2 font-bold text-center text-sm text-black">
                        Don’t have an account?{' '}
                        <a href="/signup" className="text-[#A18ADF] font-medium">
                            Sign Up
                        </a>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default LoginForm;
