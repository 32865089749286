// src/screens/Login/Login.js
import React from 'react';
import Skeleton from '../../components/LoginSignUpSkeleton/loginSignupSkeleton.jsx';
import LoginForm from './Login.jsx';
import { useEffect, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';

const LoginPage = () => {
    const { logout } = useContext(AuthContext);

    useEffect(() => {
        logout();
    }, [logout]);

    return (
      <Skeleton>
        <LoginForm />
      </Skeleton>
    )
};

export default LoginPage;
