import React from 'react';

const ReusableButton = ({ onClick, label, icon, bgColor, textColor, additionalClasses = "", disabled = false }) => {
    return (
        <button 
            onClick={onClick}
            disabled={disabled}
            className={`${bgColor} ${textColor} py-2 rounded-full font-poppins text-sm font-large ${additionalClasses} ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
        >
            {icon && <span className="">{icon}</span>}
            {label && <span>{label}</span>}
        </button>
    );
};

export default ReusableButton;

