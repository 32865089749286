import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Rec from '../../assets/images/recblue.svg';
import Question from '../../assets/images/question.svg';
import useScribeNow from "../../components/ScribeNow/scribeNow";
import useSaveForLater from "../../components/SaveLater/saveLater";
import useSuggestions from "../../components/Suggestions/suggestions";
import ReusableButton from "../../components/Button/button";
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
import useRecording from "../../hooks/useRecording";

const VideoRecording = () => {
    const location = useLocation();
    const { name, appointmentId } = location.state || {};
    const [isMeetingStarted, setIsMeetingStarted] = useState(false);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const handleScribeNow = useScribeNow(name);
    const handleSaveForLater = useSaveForLater();
    const { suggestions, generateSuggestions, removeSuggestion } = useSuggestions();
    const videoRef = useRef(null);
    const roomID = (Math.floor(Math.random() * 10000) + "");
    const userID = Math.floor(Math.random() * 10000) + "";
    const userName = name;
    const zcRef = useRef(null);

    // Audio recording
    const { startRecording, stopRecording, isSaving } = useRecording(appointmentId);

    const startMeeting = async () => {
        if (videoRef.current) {
            const appID = 233848116;
            const serverSecret = "3a5647cd95c91aa3820c2f66f5919829";
            const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(appID, serverSecret, roomID, userID, userName);
            const zc = ZegoUIKitPrebuilt.create(kitToken);

            zcRef.current = zc;

            zc.joinRoom({
                container: videoRef.current,
                scenario: {
                    mode: ZegoUIKitPrebuilt.OneONoneCall,
                },
                turnOnMicrophoneWhenJoining: false,
                turnOnCameraWhenJoining: false,
                showMyCameraToggleButton: true,
                showMyMicrophoneToggleButton: true,
                showAudioVideoSettingsButton: true,
                showScreenSharingButton: false,
                showTextChat: false,
                showUserList: true,
                maxUsers: 2,
                sharedLinks: [
                    {
                        url:  `http://localhost:3000/videorecording/${roomID}`,
                    }
                ],
                layout: "Auto",
                showLayoutButton: false,
                onLeaveRoom: () => {
                    endMeeting();
                },
                onJoinRoom: () => {
                    startRecording();

                    setIsMeetingStarted(true);
                    setShowSuggestions(true);
                    generateSuggestions();
                },
            });
        }
    };

    const endMeeting = () => {
        if (zcRef.current) {
            zcRef.current.destroy();
        }
        if (videoRef.current && videoRef.current.srcObject) {
            videoRef.current.srcObject.getTracks().forEach(track => track.stop());
            videoRef.current.srcObject = null;
        }

        setIsMeetingStarted(false);
        setShowSuggestions(false);
        setShowOptions(true);

        stopRecording();
    };

    useEffect(() => {
        console.log("Starting meeting with", name, appointmentId);
        startMeeting();
        return () => {
            if (zcRef.current) {
                zcRef.current.destroy();
            }
        };
    }, []);

    return (
        <div className="px-6 py-4 bg-[#EBEDFD] min-h-screen flex flex-col">
            <p>IS SAVING {isSaving.toString()}</p>
            <h1 className="text-xl font-extrabold mb-2 font-poppins">Video Recording</h1>
            <div className="flex flex-row">
                <p className="font-poppins text-sm font-semibold">You're Currently in the session with&nbsp;</p>
                <p className="font-poppins text-sm font-semibold text-[#BD7FEC]">{name}</p>
            </div>
            <div className="flex flex-col justify-center items-center lg:-mt-16 md:mt-5 ">
                <div className=" relative w-full aspect-video rounded-2xl overflow-hidden">
                    <div ref={videoRef} className="w-full h-full" />
                    {isMeetingStarted && (
                        <div className="absolute bottom-0 left-0 lg:w-full  flex items-center justify-center">
                        </div>
                    )}
                </div>
                {showOptions && (
                    <div className="space-y-4 mt-6 flex flex-col justify-center items-center">
                        <ReusableButton
                            onClick={() => handleScribeNow(appointmentId)}
                            label={isSaving ? "Saving..." : "Scribe Now"}
                            bgColor="bg-[#121212]"
                            textColor="text-[#FDFBFC]"
                            additionalClasses="px-20"
                            disabled={isSaving}
                        />
                        <ReusableButton
                            onClick={handleSaveForLater}
                            label={isSaving ? "Saving..." : "Save for Later"}
                            bgColor="bg-[#83C3EC]"
                            textColor="text-[#FDFBFC]"
                            additionalClasses="px-20"
                            disabled={isSaving}
                        />
                    </div>
                )}
                {showSuggestions && isMeetingStarted && (
                    <div className="space-y-4 mt-6 flex flex-col justify-center items-center">
                        {suggestions.map((suggestion, index) => (
                            <div
                                key={index}
                                className="flex flex-row bg-[#FDFBFC] text-[#000000] rounded-lg shadow-custom">
                                <div className="relative">
                                    <img
                                        src={Rec}
                                        alt="Rec"
                                        className="mr-4"
                                    />
                                    <img
                                        src={Question}
                                        alt="Question"
                                        className="absolute top-3 left-5"
                                    />
                                </div>
                                <p className="font-poppins text-sm font-semibold tracking-wide mt-4 mr-2">
                                    {suggestion}
                                </p>
                                <p
                                    className="mr-2 text-[#ACACAC] cursor-pointer"
                                    onClick={() => removeSuggestion(index)}
                                >
                                    x
                                </p>
                            </div>
                        ))}
                    </div>
                )}
                {!isMeetingStarted && !showOptions && (
                    <div className="flex flex-col justify-center items-center lg:-mt-10 md:mt-6">
                        <p className="font-poppins text-md font-semibold text-[#BD7FEC] text-center">Connect, Converse, Capture Every Emotion!</p>
                        <p className="font-poppins text-md font-large text-[#8B8888] text-center lg:mx-52 md:mx-20">Welcome to your virtual consultation. As you engage with your patient, our intelligent scribing tool will record not just the words but the emotions too, ensuring every important detail is captured. Start your video call and let the conversation flow!</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default VideoRecording;
