import React from "react";
import Skeleton from "../../components/Skeleton/skeleton";
import History from "../History/History";
import { useAppointments } from '../../context/AppointmentsContext';

const HistoryPage = () => {
  const { appointments } = useAppointments()
  return (
	<Skeleton appointments={appointments}>
	<History appointments={appointments} />
  </Skeleton>
  );
};

export default HistoryPage;
