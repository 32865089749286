import { useState } from 'react';

const useSuggestions = () => {
  const [suggestions, setSuggestions] = useState([]);

  const generateSuggestions = () => {
    const newSuggestions = [
      "Hey, you might also want to ask about how often do you exercise?",
      "Have you noticed any changes in your energy levels or ability to perform?",
      "What about dietary changes in the past few months?"
    ];
    setSuggestions(newSuggestions);
  };

  const removeSuggestion = (index) => {
    setSuggestions((prev) => prev.filter((_, i) => i !== index));
  };

  return {
    suggestions,
    setSuggestions, // Exposing setSuggestions to allow external updates
    generateSuggestions,
    removeSuggestion,
  };
};

export default useSuggestions;
