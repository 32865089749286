import React, { useState } from 'react';
import axios from 'axios';
import { BASE_API_URL } from "../../api";

const SignUpForm = () => {
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        specification: '',
        role: ''
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    // Handle input change
    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        try {
            // Log formData before making the request
            console.log('Submitting form data:', formData);

            const response = await axios.post(`${BASE_API_URL}/api/signup`, {
                username: formData.username,
                password: formData.password,
                email: formData.email,
                specification: formData.specification,
                role: "user",
            });
            if (response.status === 201) {
                setSuccess(response.data.message);
                // Optionally, redirect to login page
                // window.location.href = '/login';
            }
        } catch (error) {
            console.error('Error during signup:', error.response?.data || error.message);

            // If the API returns a 400 error, show the message
            if (error.response?.status === 400) {
                setError('Bad Request: ' + (error.response.data.message || 'Check the input fields.'));
            } else {
                setError('Sign up failed. Please try again.');
            }
        }
    };

    return (
        <div className="bg-[#EBEDFD] flex justify-center items-center min-h-screen">
            <div className="w-9/12">
                <h2 className="text-2xl font-extrabold font-poppins text-black mb-4">Sign up</h2>

                {/* Display error or success messages */}
                {error && <p className="text-red-500">{error}</p>}
                {success && <p className="text-green-500">{success}</p>}

                <form onSubmit={handleSubmit}>
                    {/* Name Field */}
                    <div className="mb-2">
                        <label className="block text-base font-bold text-black">Name</label>
                        <input
                            type="text"
                            name="username"
                            placeholder="Enter your Name"
                            value={formData.username}
                            onChange={handleInputChange}
                            className="w-full px-3 py-1 border-b-2 border-black text-gray-600 focus:outline-none focus:border-black bg-transparent text-sm"
                        />
                    </div>

                    {/* Email Field */}
                    <div className="mb-2">
                        <label className="block text-base font-bold text-black">Email</label>
                        <input
                            type="email"
                            name="email"
                            placeholder="Enter your Email"
                            value={formData.email}
                            onChange={handleInputChange}
                            className="w-full px-3 py-1 border-b-2 border-black text-gray-600 focus:outline-none focus:border-black bg-transparent text-sm"
                        />
                    </div>

                    {/* Specialization Field */}
                    <div className="mb-2">
                        <label className="block text-base font-bold text-black">Specialization</label>
                        <input
                            type="text"
                            name="specification"
                            placeholder="Enter your Specialization"
                            value={formData.specification}
                            onChange={handleInputChange}
                            className="w-full px-3 py-1 border-b-2 border-black text-gray-600 focus:outline-none focus:border-black bg-transparent text-sm"
                        />
                    </div>

                    {/* Role Field */}
                    {/* <div className="mb-2">
                        <label className="block text-base font-bold text-black">Role</label>
                        <input
                            type="text"
                            name="role"
                            placeholder="Enter your Role"
                            value={formData.role}
                            onChange={handleInputChange}
                            className="w-full px-3 py-1 border-b-2 border-black text-gray-600 focus:outline-none focus:border-black bg-transparent text-sm"
                        />
                    </div> */}

                    {/* Password Field */}
                    <div className="mb-2">
                        <label className="block text-base font-bold text-black">Password</label>
                        <input
                            type="password"
                            name="password"
                            placeholder="Enter your Password"
                            value={formData.password}
                            onChange={handleInputChange}
                            className="w-full px-3 py-1 border-b-2 border-black text-gray-600 focus:outline-none focus:border-black bg-transparent text-sm"
                        />
                    </div>

                    {/* Remember Me Section */}
                    <div className="flex items-center mb-6">
                        <input
                            type="checkbox"
                            className="w-4 h-4 border-2 border-black mr-2"
                        />
                        <label className="text-black text-sm font-poppins">Remember Me</label>
                    </div>

                    {/* Sign Up Button */}
                    <button
                        type="submit"
                        className="w-full bg-black text-white py-2 rounded-full text-sm font-bold hover:bg-gray-800 transition duration-300"
                    >
                        Sign up
                    </button>

                    {/* Log In Link */}
                    <div className="mt-2 font-bold text-center text-sm text-black">
                        Already have an account?{' '}
                        <a href="/login" className="text-[#A18ADF] font-medium">
                            Log in
                        </a>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SignUpForm;
