import { useNavigate } from 'react-router-dom';

const useScribeNow = (name) => {
    const navigate = useNavigate();

    const handleScribeNow = (appointmentId, template = null) => {
        console.log("Scribing with following params:", name, appointmentId, template);
        navigate('/scribing', { state: { name, appointmentId, template } });
        console.log("id"+appointmentId)
    };

    return handleScribeNow;
};

export default useScribeNow;
