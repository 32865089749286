import { useState } from "react";
import {
  AWS_ACCESS_KEY_ID,
  AWS_SECRET_ACCESS_KEY,
  AWS_BUCKET_NAME,
} from "../api";
import AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";

const useSaveAudio = (appointmentId) => {
  const [isSaving, setIsSaving] = useState(false);
  const [uploadStatus, setUploadStatus] = useState([]);
  const folderName = appointmentId 
  const handleSaveAudio = async (audioChunks) => {
    setIsSaving(true);
    try {
      // Combine all audio chunks into a single blob
      const audioBlob = new Blob(audioChunks, { type: "audio/webm" });
      
      // Convert audio blob to WAV format
      const wavBlob = await convertToWav(audioBlob);
      if (!wavBlob) {
        throw new Error("Failed to convert audio blob to WAV format.");
      }

      const fileName = `audio.wav`;

      const s3Client = new AWS.S3({
        accessKeyId: AWS_ACCESS_KEY_ID,
        secretAccessKey: AWS_SECRET_ACCESS_KEY,
        region: "us-east-1",
      });

      const params = {
        Bucket: AWS_BUCKET_NAME,
        Key: `${folderName}/${fileName}`,
        Body: wavBlob, // Make sure we're passing the converted wavBlob here
        ContentType: "audio/wav",
      };

      // Upload to S3
      const data = await s3Client.upload(params).promise();
      console.log("Upload successful:", data);

      // Update upload status
      setUploadStatus((prevStatus) => [
        ...prevStatus,
        { status: "success", timestamp: new Date() },
      ]);
    } catch (error) {
      console.error("Error uploading audio:", error);

      // Update upload status with the error
      setUploadStatus((prevStatus) => [
        ...prevStatus,
        { status: "error", timestamp: new Date(), error: error.message },
      ]);
    }

    setIsSaving(false);
  };

  return { isSaving, handleSaveAudio, uploadStatus, folderName };
};

const convertToWav = async (audioBlob) => {
  try {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const arrayBuffer = await audioBlob.arrayBuffer();

    // Decode the audio data
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
    const numberOfChannels = audioBuffer.numberOfChannels;
    const length = audioBuffer.length * numberOfChannels;
    const sampleRate = audioBuffer.sampleRate;
    const wavBuffer = new ArrayBuffer(44 + length * 2);
    const view = new DataView(wavBuffer);

    // Write WAV header
    writeString(view, 0, "RIFF");
    view.setUint32(4, 36 + length * 2, true);
    writeString(view, 8, "WAVE");
    writeString(view, 12, "fmt ");
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true); // PCM format
    view.setUint16(22, numberOfChannels, true);
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, sampleRate * 2 * numberOfChannels, true);
    view.setUint16(32, numberOfChannels * 2, true);
    view.setUint16(34, 16, true); // Bits per sample
    writeString(view, 36, "data");
    view.setUint32(40, length * 2, true);

    // Write PCM audio data
    for (let i = 0, offset = 44; i < length; i++) {
      const sample = audioBuffer.getChannelData(0)[i];
      view.setInt16(offset + i * 2, sample * 0x7fff, true);
    }

    return new Blob([wavBuffer], { type: "audio/wav" });
  } catch (error) {
    console.error("Error converting to WAV:", error);
    return null;
  }
};

const writeString = (view, offset, string) => {
  for (let i = 0; i < string.length; i++) {
    view.setUint8(offset + i, string.charCodeAt(i));
  }
};

export default useSaveAudio;
