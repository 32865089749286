import React, { useCallback, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import loading from "../../assets/images/loading.svg";
import { SocketContext } from "../../context/SocketContext";

import exampleTemplate from "../../assets/doctor_template.json";

const Scribing = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { name, appointmentId, template } = location.state || {};
  console.log("Scribing:", name, appointmentId, template);

  const { socket, connected } = useContext(SocketContext);

  const transcribe = useCallback(
    (appointmentId) => {
      console.log("Scribing...", appointmentId);

      const message = {
        message_type: 1,
        message_id: 1,
        route: "transcribe",
        message_object: {
          appointment_id: appointmentId,
          DoctorTemplate: template || exampleTemplate,
        },
      };
      console.log("Emitting transcribe event with message", message);

      socket.emit("message", [
        message.message_type,
        message.message_id,
        message.route,
        message.message_object,
      ]);
    },
    [socket, template]
  );

  const handleNewScribingMessage = useCallback(
    (message) => {
      console.log("New Scribing Message:", message);
      if (message.length !== 4) {
        return;
      }

      const responseObject = message[3];

      // Check if the transcription is sent but the status is not 200
      if (
        responseObject.transcription.length === 2 &&
        responseObject.transcription[1] !== 200
      ) {
        console.log("Transcription failed");
        return;
      }

      navigate("/scribingdone", { state: { name, appointmentId } });
    },
    [navigate, name, appointmentId]
  );

  useEffect(() => {
    console.log("[Scribing] location state:", location.state);
    if (!appointmentId) navigate("/");

    if (connected) {
      transcribe(appointmentId);
      socket.on("message", handleNewScribingMessage);
    }

    return () => {
      socket.off("message", handleNewScribingMessage);
    };
  }, [
    name,
    appointmentId,
    socket,
    navigate,
    transcribe,
    handleNewScribingMessage,
    connected,
    location.state,
  ]);

  return (
    <div className="px-6 py-4 bg-[#EBEDFD] min-h-screen">
      <h1 className="text-xl font-extrabold mb-2 font-poppins">Scribing</h1>
      <div className="flex flex-row">
        {name ? (
          <p className="font-poppins text-sm font-large">
            Your Recording Session with {name}&nbsp;
          </p>
        ) : (
          <p className="font-poppins text-sm font-large">
            Your Recording Session&nbsp;
          </p>
        )}
        <p className="font-poppins text-sm font-large">
          is being Scribed, Please wait.
        </p>
      </div>
      <div className="flex justify-center items-center h-[calc(100vh-160px)]">
        <img src={loading} alt="loading" className="h-56 w-56 animate-spin" />
      </div>
    </div>
  );
};

export default Scribing;
