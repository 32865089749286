import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReusableButton from "../../components/Button/button";
import { SocketContext } from "../../context/SocketContext";
import { AWS_AUDIO_URL } from "../../api";

const ScribingDone = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { name, appointmentId } = location.state || {};
  const [selectedEmotion, setSelectedEmotion] = useState("Embarrassment");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const emotions = [
    "Admiration",
    "Adoration",
    "Aesthetic Appreciation",
    "Amusement",
    "Anger",
    "Anxiety",
    "Awe",
    "Awkwardness",
    "Boredom",
    "Calmness",
    "Concentration",
    "Confusion",
    "Contemplation",
    "Contempt",
    "Contentment",
    "Craving",
    "Desire",
    "Determination",
    "Disappointment",
    "Disgust",
    "Distress",
    "Doubt",
    "Ecstasy",
    "Embarrassment",
    "Empathic Pain",
    "Entrancement",
    "Envy",
    "Excitement",
    "Fear",
    "Guilt",
    "Horror",
    "Interest",
    "Joy",
    "Love",
    "Nostalgia",
    "Pain",
    "Pride",
    "Realization",
    "Relief",
    "Romance",
    "Sadness",
    "Satisfaction",
    "Shame",
    "Surprise (negative)",
    "Surprise (positive)",
    "Sympathy",
    "Tiredness",
    "Triumph",
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [emotionGraphUrl, setEmotionGraphUrl] = useState(null);

  const { socket, connected } = useContext(SocketContext);

  const handleOpenPDF = () => {
    navigate("/form", { state: { name, appointmentId } });
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const selectEmotion = (emotion) => {
    setEmotionGraphUrl(null);
    setSelectedEmotion(emotion);
    setDropdownOpen(false);
  };

  const handleNewEmotionGraphMessage = (message) => {
    console.log(message);
    if (message.length !== 4) {
      setIsLoading(false);
      console.log("Invalid message format");
      return;
    }

    const responseObject = message[3];
    console.log("Emotion Graph Response:", responseObject);
    if (responseObject.length === 2 && responseObject[1] !== 200) {
      setIsLoading(false);
      console.log("Emotion Graph failed");
      return;
    }

    const emotionData = JSON.parse(responseObject[0]);
    console.log("Emotion Data:", emotionData);
    setEmotionGraphUrl(
      `${AWS_AUDIO_URL}/${appointmentId}/${selectedEmotion}.png`
    );
    console.log(emotionGraphUrl);
    setIsLoading(false);
  };

  useEffect(() => {
    const getEmotionGraph = () => {
      setIsLoading(true);
      console.log(
        "Emitting emotion graph event for appointment:",
        appointmentId
      );
      socket.emit("message", [
        1,
        1,
        "hume_ai",
        {
          appointment_id: appointmentId,
          Emotion: selectedEmotion,
        },
      ]);
    };

    if (connected) {
      getEmotionGraph();
      socket.on("message", handleNewEmotionGraphMessage);
    }

    return () => {
      socket.off("message", handleNewEmotionGraphMessage);
    };
  }, [selectedEmotion, appointmentId, socket, connected]);

  return (
    <div className="px-6 py-4 bg-[#EBEDFD] min-h-screen relative">
      <h1 className="text-xl font-extrabold mb-2 font-poppins">Scribing</h1>
      <div className="flex flex-row">
        <p className="font-poppins text-sm font-large">
          Your Recording Session with
        </p>
        {name ? (
          <p className="font-poppins text-sm font-semibold text-[#BD7FEC]">
             &nbsp;{name}
          </p>
        ) : (
          <div></div>
        )}

        <p className="font-poppins text-sm font-large">
          &nbsp;has been scribed.
        </p>
      </div>
      <div className="flex flex-col space-y-4 mt-6 justify-center items-center">
        <ReusableButton
          label="Open Scribed PDF"
          bgColor="bg-[#121212]"
          textColor="text-[#FDFBFC]"
          additionalClasses="px-4 py-2 rounded-full font-poppins text-sm font-large w-1/3"
          onClick={handleOpenPDF}
        />
        <ReusableButton
          label="Download Scribed PDF"
          bgColor="bg-[#83C3EC]"
          textColor="text-[#FDFBFC]"
          additionalClasses="px-4 py-2 rounded-full font-poppins text-sm font-large w-1/3"
          onClick={() => {
            const link = `https://medai-appointments-history.s3.amazonaws.com/${appointmentId}/report.pdf`;
            window.open(link, "_blank");
          }}
        />
      </div>
      <div className="relative mt-8 z-50">
        <h1 className="text-xl font-extrabold mb-2 font-poppins">
          Emotion Graph
        </h1>
        <p className="text-md text-[#8B8888] font-medium mb-2">
          Review emotional trends from the session, giving you deeper insights
          to enhance post-visit care and ensure a personalized patient
          experience.
        </p>
        {isLoading && "Loading..."}
        {emotionGraphUrl && (
          <div className="relative">
            <img
              src={emotionGraphUrl}
              alt="graph"
              className="w-full relative z-10"
            />
            <div className="absolute right-2 top-0 mt-2 z-20">
              <button
                className="flex justify-center items-center w-40 bg-[#121212] text-[#FDFBFC] px-4 py-2 rounded-lg font-poppins text-sm font-medium"
                onClick={toggleDropdown}
              >
                {selectedEmotion}
                <span
                  className={`ml-2 inline-block transform ${
                    dropdownOpen ? "rotate-180" : ""
                  }`}
                >
                  <svg
                    className="w-4 h-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </span>
              </button>
              {dropdownOpen && (
                <ul className="absolute text-center mt-2 w-40 bg-white border border-gray-300 rounded-lg shadow-lg max-h-48 overflow-y-auto">
                  {emotions.map((emotion) => (
                    <li
                      key={emotion}
                      className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                      onClick={() => selectEmotion(emotion)}
                    >
                      {emotion}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ScribingDone;
