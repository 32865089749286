import { useNavigate } from "react-router-dom";

const useSaveForLater = () => {
    const navigate = useNavigate();
    
    const handleSaveForLater = () => {
        console.log('Saving for later');
        navigate('/');
    };

    return handleSaveForLater;
};

export default useSaveForLater;
