import React, {
  useRef,
  useState,
  useEffect,
  createContext,
  useContext,
} from "react";
import Black from "../../assets/images/black.svg";
import ReusableButton from "../../components/Button/button";
import useScribeNow from "../../components/ScribeNow/scribeNow";
import useFetchAudio from "../../hooks/useFetchAudio"; // Import the custom hook
import Pause from "../../assets/images/pauseButton.svg";

// Define the context
const AppContext = createContext();

// Context Provider component
const AppProvider = ({ children }) => {
  const [someState, setSomeState] = useState(null);

  return (
    <AppContext.Provider value={{ someState, setSomeState }}>
      {children}
    </AppContext.Provider>
  );
};

// Custom hook to use context
const useAppContext = () => useContext(AppContext);

// Helper function to format time
const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
};

// AppointmentItem component
// AppointmentItem component
const AppointmentItem = ({
  name,
  date,
  start_time,
  end_time,
  id,
  audioRef,
  setCurrentlyPlaying,
  currentlyPlaying,
}) => {
  const { someState, setSomeState } = useAppContext();
  const handleScribe = useScribeNow(name);
  const { audioUrl, isLoading } = useFetchAudio(id);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const localAudioRef = useRef(null);

  useEffect(() => {
    if (localAudioRef.current) {
      const audioElement = localAudioRef.current;
      audioElement.addEventListener("loadedmetadata", () => {
        if (audioElement.duration > 180) setDuration(audioElement.duration);
        else setDuration(0);
      });
    }
  }, [audioUrl]);

  const handlePlayPause = () => {
    if (localAudioRef.current) {
      if (isPlaying) {
        localAudioRef.current.pause();
      } else {
        if (audioRef.current && audioRef.current !== localAudioRef.current) {
          audioRef.current.pause();
        }
        audioRef.current = localAudioRef.current;
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
      setCurrentlyPlaying(isPlaying ? null : localAudioRef.current);
    }
  };

  useEffect(() => {
    if (localAudioRef.current) {
      const audioElement = localAudioRef.current;
      const updateTime = () => setCurrentTime(audioElement.currentTime);
      audioElement.addEventListener("timeupdate", updateTime);

      return () => {
        audioElement.removeEventListener("timeupdate", updateTime);
      };
    }
  }, [audioUrl]);

  const handleSeek = (event) => {
    const seekTime = event.target.value;
    localAudioRef.current.currentTime = seekTime;
    setCurrentTime(seekTime);
  };
  const handleScribeClick = useScribeNow(name);

  const isCurrentPlaying = currentlyPlaying === localAudioRef.current;

  if (!audioUrl || duration === 0 || isLoading) {
    return <p></p>;
  }

  return (
    <div className="bg-white rounded-3xl p-4 shadow-md">
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-lg font-semibold text-purple-500">{name}</h2>
          <div className="flex items-center shadow-custom2 rounded-3xl mb-2">
            <div className="flex-grow w-full h-14 mt-2 mb-2">
              <div className="flex items-center justify-center space-x-2">
                <img
                  src={isCurrentPlaying ? Pause : Black}
                  alt={isCurrentPlaying ? "Pause" : "Play"}
                  className="cursor-pointer"
                  onClick={handlePlayPause}
                />

                {/* Custom Progress Bar with Gradient */}
                <input
                  type="range"
                  min="0"
                  max={duration || 0}
                  value={currentTime}
                  onChange={handleSeek}
                  className="appearance-none w-96 h-2 cursor-pointer lg:w-[700px] "
                  style={{
                    background: `linear-gradient(to right, #BD7FEC 0%, #79CBEB ${((currentTime / duration) * 100) || 0}%)`, // Gradient
                    borderRadius: '5px',
                    
                  }}
                />
                {/* Style for the thumb */}
                <style>
                  {`
                    input[type='range']::-webkit-slider-thumb {
                      -webkit-appearance: none;
                      appearance: none;
                      width: 20px;
                      height: 20px;
                      background: white;
                      border: 2px solid #79CBEB; /* Border color */
                      border-radius: 50%;
                      cursor: pointer;
                      margin-top: -8px; /* Center thumb on the track */
                    }
                    input[type='range']::-moz-range-thumb {
                      width: 20px;
                      height: 20px;
                      background: white;
                      border: 2px solid #79CBEB;
                      border-radius: 50%;
                      cursor: pointer;
                    }
                    input[type='range']::-ms-thumb {
                      width: 20px;
                      height: 20px;
                      background: white;
                      border: 2px solid #79CBEB;
                      border-radius: 50%;
                      cursor: pointer;
                    }
                    input[type='range']::-webkit-slider-runnable-track {
                      height: 4px; /* Height of the track */
                      border-radius: 5px;
                    }
                    input[type='range']::-moz-range-track {
                      height: 4px;
                      border-radius: 5px;
                    }
                    input[type='range']::-ms-track {
                      height: 4px;
                      border-radius: 5px;
                    }
                  `}
                </style>
              </div>
            </div>
            <span className="ml-6 text-lg text-[#121212] font-semibold mr-6 mt-2">
              {formatTime(duration)}
            </span>
          </div>
          <div className="flex flex-row justify-between">
            <div>
              <p className="text-sm text-gray-400">Appointment Details</p>
              <p className="text-xs text-gray-700">{date}</p>
              <p className="text-xs text-gray-700">
                {start_time.slice(0, -3)} - {end_time.slice(0, -3)}
              </p>
            </div>
            <div className="flex items-center">
              <ReusableButton
                onClick={() => handleScribeClick(id)}
                label={"Scribe Now"}
                bgColor="bg-[#121212]"
                additionalClasses="p-6 px-6 rounded-full text-white"
              />
            </div>
          </div>
        </div>
      </div>
      {audioUrl && <audio ref={localAudioRef} src={audioUrl} />}
    </div>
  );
};

// History component
const History = ({ appointments = [] }) => {
  const [socketData, setSocketData] = useState(null);
  const audioRef = useRef(null);
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);

  useEffect(() => {
    const socket = new WebSocket("wss://medai-backend.rhodium-tech.com");

    socket.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        console.log("Received socket data:", data);
        setSocketData(data);
      } catch (error) {
        console.error("Error parsing socket data:", error);
      }
    };

    return () => {
      socket.close();
    };
  }, []);

  return (
    <AppProvider>
      <div className="bg-gray-100 min-h-screen p-6">
        <h1 className="text-2xl font-bold mb-6">History</h1>
        {socketData && (
          <div className="p-4 bg-blue-100 rounded-lg">
            <h2>Socket Data:</h2>
            <pre>{JSON.stringify(socketData, null, 2)}</pre>
          </div>
        )}
        <div className="space-y-4 w-full">
          {appointments.map((appointment) => (
            <AppointmentItem
              key={appointment.id}
              {...appointment}
              audioRef={audioRef}
              setCurrentlyPlaying={setCurrentlyPlaying}
              currentlyPlaying={currentlyPlaying} // Pass currentlyPlaying state
            />
          ))}
        </div>
      </div>
    </AppProvider>
  );
};

export default History;
