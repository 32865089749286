import React from "react";
import Skeleton from '../../components/Skeleton/skeleton';
import RecordSession from "./recordSession";

const RecordPage = () => {
    return (
        <Skeleton>
            <RecordSession/>
        </Skeleton>
    );
};

export default RecordPage;