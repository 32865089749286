// Skeleton.js
import React from 'react';
import image from "../../assets/images/login.png";
import imageTop from "../../assets/images/Group.png";
import imageBottom from "../../assets/images/resized_image.png";
const Skeleton = ({ children }) => {
    return (
        <div className="flex h-screen w-screen bg-black overflow-hidden absolute -z-20">
            <div className="w-2/5 bg-black space-y-0 position-absolute -z-20">
                <div>
                    <img
                        src={imageTop}
                        alt="Illustration"
                        className="w-3/4 h-1/4"
                    />
                </div>
                <div className=" flex flex-col items-center relative bottom-24"> 
                    <img
                        src={image}
                        alt="Illustration"
                        className="w-72 h-auto"
                    />
                </div>
                <div className='relative bottom-36 left-72 -z-10'>
                    <img
                        src={imageBottom}
                        alt="Illustration"
                        className="w-full h-full"
                    />
                </div>
                
            </div>
            <div className="flex-1 m-4 bg-[#EBEDFD] rounded-3xl shadow-lg overflow-auto">
                {children}
            </div>
            
        </div>
    );
};

export default Skeleton;
