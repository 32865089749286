import { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import { AWS_ACCESS_KEY_ID, AWS_SECRET_ACCESS_KEY, AWS_BUCKET_NAME } from '../api';

const useFetchAudio = (appointmentId) => {
    const [audioUrl, setAudioUrl] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAudio = async () => {
            if (!appointmentId) {
                console.error('No appointment ID provided.');
                setIsLoading(false);
                return;
            }

            setIsLoading(true);
            setError(null);

            try {
                // Configure AWS SDK
                const s3Client = new AWS.S3({
                    accessKeyId: AWS_ACCESS_KEY_ID,
                    secretAccessKey: AWS_SECRET_ACCESS_KEY,
                    region: 'us-east-1', // Ensure this matches your S3 bucket region
                });
                console.log('s3 client')
                console.log(s3Client)
                // Define parameters for S3 getObject
                const params = {
                    Bucket: AWS_BUCKET_NAME,
                    Key: `${appointmentId}/audio.wav`,
                };

                // Fetch the audio file from S3
                const data = await s3Client.getObject(params).promise();
                console.log(data);
                
                // Log the data object for debugging
                console.log('S3 data object:', data);
                
                const audioBlob = new Blob([data.Body], { type: 'audio/wav' });
                const url = URL.createObjectURL(audioBlob);

                // Log the audio URL to the console
                console.log(`Fetched audio URL for appointment ${appointmentId}:`, url);

                setAudioUrl(url);
            } catch (error) {
                console.error('Error fetching audio:', error);
                setError(error.message || 'Unknown error');
            } finally {
                setIsLoading(false);
            }
        };

        fetchAudio();
    }, [appointmentId]);

    return { audioUrl, isLoading, error };
};

export default useFetchAudio;
