import React from "react";
import { useNavigate } from "react-router-dom";
import Calender from '../../assets/images/calendar.svg';
import Clock from '../../assets/images/clock.svg';
import Logo from '../../assets/images/logo.svg';
import Search from '../../assets/images/search.svg';
import Upload from '../../assets/images/upload.svg';
import Line from '../../assets/images/line.svg';

const Button1 = ({ icon, text }) => (
    <div className="flex items-center p-2 mx-4 bg-[#FDFBFC] rounded-lg" style={{ width: '88%' }}>
        <img src={icon} alt={text} className="h-6 w-6 mr-2 ml-2" />
        <input
            type='text'
            placeholder={text}
            className="flex-grow placeholder-[#00000D] text-sm font-medium bg-[#FDFBFC] outline-none"
            style={{ minWidth: '0' }}
        />
    </div>
);

const Button2 = ({ icon, text, onClick }) => (
    <div className="flex items-center">
        <button className="flex flex-row" onClick={onClick}>
            <img src={icon} alt={text} className="h-6 w-6 mr-4 ml-8" />
            <span className="flex-1 text-[#FDFBFC] text-md font-medium bg-transparent font-poppins">
                {text}
            </span>
        </button>
    </div>
);

const Button3 = ({ icon, text, onClick }) => (
    <button className="flex items-center p-2 mx-4 bg-[#FDFBFC] rounded-lg" onClick={onClick} style={{ width: '88%' }}>
        <img src={icon} alt={text} className="h-6 w-6 mr-2 ml-2" />
        <span className="flex-grow text-[#00000D] text-sm font-medium bg-[#FDFBFC] outline-none text-left">
            {text}
        </span>
    </button>
);

const Skeleton = ({  children, appointments }) => {
    const navigate = useNavigate();
    return (
        <div className="flex h-screen w-screen bg-[#121212] overflow-hidden">
            <div className="flex flex-col items-center w-1/4 max-w-xs bg-[#121212] py-8 px-4">
                <a href="/">
                    <img src={Logo} alt="Logo" className="h-16 mb-6" />
                </a>
                <div className="w-full space-y-6">
                    <Button1 icon={Search} text="Search" />
                    <Button3 icon={Calender} text="Appointments" onClick={()=>navigate('/appointments')}/>
                    <Button2 icon={Upload} text="Upload" onClick={()=>navigate('/upload')}/>
                    <img src={Line} alt="line" className="w-full"/>
                    <Button2 icon={Clock} text="History" onClick={()=> navigate("/history", { state: { appointments } })} />
                </div>         
            </div>
            <div className="flex-1 m-4 bg-[#EBEDFD] rounded-3xl shadow-lg overflow-auto">
                {children}
            </div>
        </div>
    );
};

export default Skeleton;