// src/context/AuthContext.jsx
import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [tokens, setTokens] = useState(() => {
        const savedTokens = localStorage.getItem('tokens');
        return savedTokens ? JSON.parse(savedTokens) : null;
    });

    useEffect(() => {
        if (tokens) {
            localStorage.setItem('tokens', JSON.stringify(tokens));
        } else {
            localStorage.removeItem('tokens');
        }
    }, [tokens]);

    const isAuthenticated = !!tokens?.access_token;

    const logout = () => {
        setTokens(null);
        // localStorage.removeItem('tokens'); // Optional, since useEffect handles this
    };

    return (
        <AuthContext.Provider value={{ tokens, setTokens, isAuthenticated, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
