// AppointmentPage.jsx
import React, { useContext } from "react";
import { AuthContext } from '../../context/AuthContext';
import Appointment from "./Appointment";

const AppointmentPage = () => {
    const { tokens } = useContext(AuthContext); 

    return (
        <Appointment tokens={tokens} /> 
    );
};

export default AppointmentPage;
